
import ContractsCommentsService from "@/modules/contracts-comments/contracts-comments-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            ContractsCommentsService.create(payload).then(response => {
                resolve({
                    message: "ContractsComments criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar ContractsComments",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            ContractsCommentsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "ContractsComments atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar ContractsComments",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            ContractsCommentsService.remove(id).then(response => {
                resolve({
                    message: "ContractsComments removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover ContractsComments",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            ContractsCommentsService.delete(id).then(response => {
                resolve({
                    message: "ContractsComments deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar ContractsComments",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            ContractsCommentsService.get(id).then(response => {
                resolve({
                    message: "ContractsComments encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsComments",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            ContractsCommentsService.paginate(query).then(response => {
                resolve({
                    message: "ContractsComments encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsComments",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            ContractsCommentsService.all().then(response => {
                resolve({
                    message: "ContractsComments encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsComments",
                    success: false,
                    error
                });
            })
        })
    }
}
